.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-color: #227222;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover { 
    background-color: #13b313;
    animation-name: unset;
}

.whatsapp-icon:hover, .tg-icon:hover {
  opacity: 0.8;
}

.whatsapp-icon svg, .tg-icon svg {
  width: 45px;
  height: 45px;
}


.tg-icon {
    bottom: 170px;
    background: #03a9f4;
}

.tg-icon:hover {
    background: #0d8cc5;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(15deg)scale(1.2);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}